.selectVolumeCard {
  width: 80%;
  position: relative;
  bottom: 0px;
  transform: translateX(13%);
  padding-bottom: 150px;
  .textContent {
    text-align: left;
    width: 100%;
    margin-left: 30px;
    .text1 {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .line {
        width: 52px;
        height: 2px;
        left: 0px;
        top: calc(50% - 2px / 2 + 2px);
        background: #ec7963;
        margin-right: 10px;
      }
      .text {
        color: #ec7963;
      }
    }
    .text2 {
      font-family: Noto Sans CJK TC;
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 72px;
      letter-spacing: -0.4px;
      color: #0e172c;
    }

    .text3 {
      display: flex;
      padding: 15px;
      .elementary {
        cursor: pointer;
        margin-right: 25px;
        width: 57px;
        text-align: center;
        color: #6b6b6b;
        &.show {
          color: #000000;
        }
        .line {
          height: 2px;
          left: 288px;
          top: 6274px;
          background: #ec7963;
          border-radius: 0.711111px;
        }
      }
      .junior {
        cursor: pointer;
        margin-right: 25px;
        width: 57px;
        text-align: center;
        color: #6b6b6b;
        &.show {
          color: #000000;
        }
        .line {
          height: 2px;
          left: 288px;
          top: 6274px;
          background: #ec7963;
          border-radius: 0.711111px;
        }
      }
      .senior {
        cursor: pointer;
        width: 57px;
        text-align: center;
        color: #6b6b6b;
        &.show {
          color: #000000;
        }
        .line {
          height: 2px;
          left: 288px;
          top: 6274px;
          background: #ec7963;
          border-radius: 0.711111px;
        }
      }
    }
  }
  .volumeContent {
    width: 100%;
    .card {
      cursor: pointer;
      padding: 15px;
      display: inline-block;
      width: 50%;
      & > div > img {
        width: 100%;

        border-top-left-radius: 17.0667px;
        border-top-right-radius: 17.0667px;
      }
      .text {
        padding: 35px;
        font-family: Noto Sans CJK TC;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;

        border: 1px solid #e4e7ec;
        box-sizing: border-box;
        border-bottom-left-radius: 17.0667px;
        border-bottom-right-radius: 17.0667px;
        position: relative;
        background-color: white;
        top: -10px;
      }
    }
  }
}
