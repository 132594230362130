@import "../../../style/media";

.userInfoArea {
  position: relative;
  .loginBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    cursor: pointer;
  }
  .userInfoContent {
    width: 100vw;
    display: flex;
    padding: 40px 22px;
    font-size: 18px;
    color: #9e9e9e;
    height: 72px;
    font-weight: 700;
    font-size: 18px;
    justify-content: space-between;
    align-items: center;
    .logoContainer {
      display: flex;
      flex-direction: row;
      width:fit-content;
      align-items: center;
      gap:16px;
    }
    .userIcon {
      display: flex;
      align-items: center;
      .userBtn {
        cursor: pointer;
        p {
          margin-top: 8px;
          position: absolute;
          width: 200px;
          height: 54px;
          right: 0px;
          margin-right: 26px;
          box-sizing: border-box;
          padding-left: 24px;
          display: flex;
          align-items: center;
          background-color: #fff;
          border-radius: 12px;
          box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.05);
          color: #212b36;
          font-weight: 400;
          font-size: 14px;
          &:hover {
            background-color: #fef2f0;
          }
        }
      }
    }

    .userList {
      position: absolute;
      right: 0px;
      text-align: left;
      width: 240px;
      border-radius: 8px;
      text-align: left;
      box-shadow: 0px 10px 24px rgb(100, 100, 100);
      background: #ffffff;
      & > div {
        cursor: pointer;
        position: static;
        width: 240px;
        height: 48px;
        left: 0px;
        top: 96px;

        flex: none;
        order: 2;
        flex-grow: 0;
        margin: 0px 0px;

        display: flex;
        justify-content: flex-start;
        align-items: center;
        & > a {
          position: static;
          width: 56px;
          height: 24px;
          left: 0px;
          top: calc(50% - 24px / 2);
          font-family: Noto Sans CJK TC;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          /* identical to box height, or 171% */
          /* UI/10 */
          color: #242c3f;

          /* Inside Auto Layout */

          flex: none;
          order: 0;
          flex-grow: 0;
          margin: 0px 30px;
        }
      }
      & > div:hover {
        background-color: #fdf5f0;
        flex: none;
        order: 2;
        flex-grow: 0;
        margin: 0px 0px;
      }

      .name {
        /* Ch/Body/Body5-ch */

        font-family: Noto Sans CJK TC;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */

        /* Text/06 */
        text-decoration: auto;
        color: #8b90a0;
      }
    }
  }
}
