.videoListContent {
  padding: 4%;
  width: 100%;
  background: #ffffff;
  border: 1px solid #e4e7ec;
  box-sizing: border-box;
  border-radius: 24px;
  .videoPlayContent {
    width: 100%;
    height: 50%;
    background-color: #454b5c;
    .videoPlay {
      height: 342px;
      display: flex;
      justify-content: center;
    }
  }
  .videoContentTitle {
    height: 10%;
    font-family: Noto Sans CJK TC;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #3a4052;
  }

  .listContent {
    height: 90%;
    .list {
      width: 100%;
      word-break: break-all;
      padding-bottom: 20px;
      .content {
        display: flex;
        width: 100%;
        height: 82px;
        padding: 1%;
        cursor: auto;
        &.show {
          cursor: pointer;
        }
        .number {
          width: 10%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-content: center;
          text-align: center;
        }
        .videoView {
          width: 144px;
          height: 82px;
          position: relative;
          background-color: #454b5c;
          .video {
            width: 100%;
            height: 100%;
            position: absolute;
          }
          video::-internal-media-controls-download-button {
            display: none;
          }

          video::-webkit-media-controls-enclosure {
            overflow: hidden;
          }

          video::-webkit-media-controls-panel {
            width: calc(100% + 30px); /* Adjust as needed */
          }
          .videoLock {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0px;
            bottom: 0px;
            & > img {
              width: 100%;
              height: 100%;
            }
          }
          .progressBG {
            position: absolute;
            width: 100%;
            height: 5px;
            left: 0px;
            bottom: 0px;
            background: #d5d7de;
          }
          .progress {
            position: absolute;
            width: 100%;
            height: 5px;
            left: 0px;
            bottom: 0px;
            background: #ec7963;
          }
        }
        .textContent {
          width: 60%;
          padding-left: 4%;
          position: relative;
          & > div {
            font-family: Noto Sans CJK TC;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            /* identical to box height, or 171% */

            /* Text/08 */

            color: #3a4052;
          }
          .time {
            font-family: Noto Sans CJK TC;
            font-style: normal;
            font-weight: normal;
            font-size: 11px;
            line-height: 16px;
            /* identical to box height, or 145% */

            /* Text/06 */

            color: #8b90a0;
            position: absolute;
            bottom: 0px;
          }
        }
      }
    }
  }
}
