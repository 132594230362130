.recode {
  width: 100%;
  border: 1px solid #e4e7ec;
  box-sizing: border-box;
  border-radius: 24px;
  padding: 0 3% 3% 3%;

  .control {
    display: flex;
    width: 100%;
    padding: 20px;
    .title {
      width: 90%;
      display: flex;
      & > div {
        padding-right: 10px;
      }
      & > img {
        background: #f9c74f;
      }
    }
    .searchBtn {
      width: 65px;
      height: 31px;
      left: 0px;
      top: 0px;
      background: #ffffff;
      border: 1px solid #d5d7de;
      box-sizing: border-box;
      border-radius: 12px;
      display: flex;
      .left {
        cursor: pointer;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #a1a4b1;
        border-right: 1px solid #d5d7de;
        border-top-left-radius: inherit;
        border-bottom-left-radius: inherit;
        &.hide{
          background: #f5f5f5;
        }
      }
      .right {
        cursor: pointer;
        width: 50%;
        display: flex;
        color: #a1a4b1;
        align-items: center;
        justify-content: center;
        border-top-right-radius: inherit;
        border-bottom-right-radius: inherit;
        &.hide{
          background: #f5f5f5;
        }
      }
    }
  }

  .videoRecodList {
    width: 100%;
    padding: 10px;
    .timestamp {
      width: 23%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .name {
      width: 50%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .right {
      width: 17%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .result {
      width: 14%;
      display: flex;
      justify-content: center;
      align-items: center;
      .resultBtn {
        cursor: pointer;
        background: #394f8a;
        border-radius: 8px;
        color: white;
        font-family: Noto Sans CJK TC;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 16px;
        width: 68px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .tableText {
      display: flex;
      font-family: Noto Sans CJK TC;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      color: #8b90a0;
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    }
    .list {
      display: flex;
      font-family: Noto Sans CJK TC;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      color: #454b5c;
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      .timestamp {
        display: flex;
      }
    }
  }
}
