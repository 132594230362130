.userInfoTable {
    margin: 0px 8px;
    width: calc(100% - 16px);
    .listHeader {
        width: 100%;
        background: #F4F6F8;
        padding: 17px 0px;
        color: #637381;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 8px;
    }
    .userInfo {
        width: 100%;
        padding: 17px 0px;
        font-size: 14px;
        color: #242C3F;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    td {
        margin: 0px 16px;
        word-wrap: break-word;
        img {
            cursor: pointer;
        }
    }

}