.purchaseFillInfos {
    padding-top: 60px;
    margin: 0 auto;
    width: 100%;
    min-width: 600px;

    .mainArea {
        margin: 0 auto;
        max-width: 800px;
        background: #ffffff;

        .back {
            width: 200px;
            height: 24px;
            left: 32px;
            top: 0px;

            /* Ch/Body/Body5-ch */

            font-family: Noto Sans CJK TC;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            /* identical to box height, or 171% */

            display: flex;
            align-items: center;

            /* Text/06 */

            color: #8b90a0;
            cursor: pointer;
        }

        .infos {
            margin: 0 auto;
            padding: 0 5px;
            max-width: 600px;
            width: 100%;
            .userInfo {
                & > h5 {
                    font-family: Noto Sans CJK TC;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 24px;
                    line-height: 36px;
                    color: #242c3f;
                    font-weight: bolder;
                }
            }

            .title {
                padding-top: 20px;
                /* Heading/CH/H2-ch */
                font-family: Noto Sans CJK TC;
                font-style: normal;
                font-weight: normal;
                font-size: 24px;
                line-height: 36px;
                /* identical to box height, or 150% */

                /* Text/10 */

                color: #242c3f;
            }

            .productMoney {
                display: flex;
                width: 100%;
                height: 40px;
                padding-bottom: 5px;
                .dicountErrorMessage {
                    color: #c33d3d;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    width: 47%;
                    padding-left: 10px;
                }
                .itemName2 {
                    color: #909090;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    font-family: Noto Sans CJK TC;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 24px;
                    width: 100px;
                    height: 100%;
                }
                .itemName3 {
                    color: #909090;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    font-family: Noto Sans CJK TC;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 24px;
                    width: 110px;
                    height: 100%;
                }
                .itemName4 {
                    color: #909090;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    font-family: Noto Sans CJK TC;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 24px;
                    width: 70px;
                    height: 100%;
                }
                & > input {
                    border: 1px solid #d5d7de;
                    box-sizing: border-box;
                    border-radius: 4px;
                    margin-left: 10px;
                    width: 160px;
                }
                & > span {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                }
                & > button {
                    width: 50px;
                    height: 100%;
                    margin-left: 7px;
                }
                .itemValue2 {
                    width: 450px;
                    float: left;
                    font-family: Noto Sans CJK TC;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 24px;
                    color: #242c3f;
                    padding-left: 40px;
                }
            }

            .itemName {
                width: 110px;
                float: left;
                /* Ch/Body/Body5-ch */
                font-family: Noto Sans CJK TC;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 24px;
                /* identical to box height, or 171% */

                /* Text/06 */

                color: #8b90a0;
            }

            .itemValue {
                width: 450px;
                float: left;
                /* Ch/Body/Body4-ch */
                font-family: Noto Sans CJK TC;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                /* identical to box height, or 150% */

                /* Text/10 */

                color: #242c3f;
            }

            .itemHalfName {
                width: 250px;
                float: left;
                /* Ch/Body/Body5-ch */

                font-family: Noto Sans CJK TC;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 24px;
                /* identical to box height, or 171% */

                /* Text/06 */

                color: #8b90a0;
            }

            .itemHalfInput {
                width: 250px;
                float: left;
                border: 1px solid #d5d7de;
                /* Ch/Body/Body4-ch */
                font-family: Noto Sans CJK TC;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                /* identical to box height, or 150% */

                /* Text/10 */

                color: #242c3f;
            }

            .itemFullName {
                width: 510px;
                float: left;
                /* Ch/Body/Body5-ch */

                font-family: Noto Sans CJK TC;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 24px;
                /* identical to box height, or 171% */

                /* Text/06 */

                color: #8b90a0;
            }

            .itemFullInput {
                width: 510px;
                float: left;
                border: 1px solid #d5d7de;
                /* Ch/Body/Body4-ch */
                font-family: Noto Sans CJK TC;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                /* identical to box height, or 150% */

                /* Text/10 */

                color: #242c3f;
            }

            input[type="radio"] {
                /* remove standard background appearance */
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                /* create custom radiobutton appearance */
                display: inline-block;
                width: 15px;
                height: 15px;
                transform: translateY(10%);
                padding: 3px;
                /* background-color only for content */
                background-clip: content-box;
                border: 1px solid #ec7963;
                background-color: white;
                border-radius: 50%;
            }

            /* appearance for checked radiobutton */
            input[type="radio"]:checked {
                background-color: #ec7963;
            }

            .invoiceInput1 {
                border: 1px solid #d5d7de;
                box-sizing: border-box;
                border-radius: 4px;
                margin-left: 10px;
                width: 160px;
            }

            .invoiceInput2 {
                border: 1px solid #d5d7de;
                box-sizing: border-box;
                border-radius: 4px;
                margin-left: 10px;
                width: 220px;
            }

            .hr {
                position: relative;
                top: 20px;
                width: 100%;
                border: 1px solid #d5d7de;
            }

            .agreeCheckBox {
                /* UI/05 */

                border: 1px solid #a1a4b1;
                box-sizing: border-box;
                border-radius: 2px;
                transform: translateY(20%);
            }

            .agreement {
                /* Ch/Body/Body5-ch */

                font-family: Noto Sans CJK TC;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 24px;
                /* identical to box height, or 171% */

                /* Character/Title .85 */

                color: rgba(0, 0, 0, 0.85);
            }
            .agreement2 {
                /* Ch/Body/Body5-ch */
                cursor: pointer;
                font-family: Noto Sans CJK TC;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 24px;

                color: #0a58ca;
                text-decoration-line: underline;
            }

            button {
                width: 200px;
                color: #ffffff;
                /* Brand/02 */

                background: #121232;
                border-radius: 4px;
            }
            button:hover {
                background: #2a2a47;
            }
        }
    }
}
