html {
  scroll-behavior: smooth;
}

.mainPage {
  margin: 0 auto;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  .page1 {
    .header {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0%);
      width: 1024px;
      text-align: center;
    }
    .bg {
      text-align: center;
      & > img {
        width: 100%;
      }
    }
  }
  .page2 {
    background-color: #27272e;
    position: relative;
    .bg {
      text-align: center;
      & > img {
        width: 100%;
      }
    }
    .btn {
      position: absolute;
      z-index: 1;
      top: 78%;
      left: 14%;
      transform: translate(0, -50%);
      width: 100%;
      height: 100%;
      & > img {
        cursor: pointer;
        width: 10%;
        height: 100%;
      }
    }
  }

  .page3 {
    position: relative;
    .bg {
      text-align: center;
      & > img {
        width: 100%;
      }
    }
  }
  .page4 {
    position: relative;
    width: 100%;
    .bg {
      text-align: center;
      width: 100%;
      & > img {
        width: 100%;
      }
    }

    .btn {
      position: absolute;
      z-index: 1;
      top: 88%;
      left: 76%;
      transform: translate(0, -50%);
      width: 100%;
      height: 100%;
      & > img {
        cursor: pointer;
        width: 10%;
        height: 100%;
      }
    }
  }
  .page5 {
    padding-bottom: 100px;
    .bg {
      text-align: center;
      & > img {
        width: 100%;
      }
    }
  }
}
