.onlineTest {
  padding: 5.5%;

  &.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .title {
    font-family: Noto Sans CJK TC;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #8b90a0;
  }
  .textContent {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 30px;
    .circle {
      width: 8px;
      height: 8px;
      background: #ec7963;
      border-radius: 50%;
      margin-right: 10px;
    }
    .text {
      font-family: Noto Sans CJK TC;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 30px;
      color: #242c3f;
    }
  }
  .listContent {
    padding-bottom: 20px;
    .testLoadingMessage {
      width: 100%;
      display: flex;
      justify-content: center;
      color: #ff6700;
      font-size: 18px;
      font-weight: 500;
    }
    .noquestion {
      color: #ff6700;
    }
    .list {
      display: flex;
      width: 100%;
      padding: 25px 20px 25px 20px;
      background: #fdf5f0;
      border-radius: 32px;
      margin-bottom: 20px;
      .icon {
        width: 15%;
        background: #ffffff;
        border-radius: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        & > img {
          width: 90%;
          height: 90%;
        }
      }
      .text {
        width: 65%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 25px;
        .content {
          .text1 {
            font-family: Noto Sans CJK TC;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;
            color: #242c3f;
          }
          .text2 {
            font-family: Noto Sans CJK TC;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            color: #a1a4b1;
          }
        }
      }
      .testBtn {
        cursor: pointer;
        width: 15%;
        display: flex;
        justify-content: center;
        align-items: center;
        //filter: grayscale(0%);
        pointer-events: all;
        &.hide {
          pointer-events: none;
          filter: grayscale(100%);
        }
      }
    }
  }
}

.dialogMessage {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0px;
  font-family: Noto Sans CJK TC;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 36px;
  color: #242c3f;
  & > div {
    padding: 20px;
    text-align: center;
  }
  .closeBtn {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 9px 16px;
    height: 40px;
    background: #fafafc;
    border-radius: 4px;
    margin-top: 12px;
  }
}
