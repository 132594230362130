html {
  scroll-behavior: smooth;
}

.mainPage {
  margin: 0 auto;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  .page1 {
    .header {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0%);
      width: 1024px;
      text-align: center;
    }
    .bg {
      text-align: center;
      & > img {
        width: 100%;
      }
    }
  }
  .page2 {
    background-color: #27272e;
    .bg {
      text-align: center;
      & > img {
        width: 100%;
      }
    }
  }

  .page3 {
    position: relative;
    .bg {
      text-align: center;
      & > img {
        width: 100%;
      }
    }
    .btn {
      cursor: pointer;
      position: absolute;
      left: 23%;
      top: 38%;
      transform: translateY(-115%) translateX(-50%);
    }
  }
  .page4 {
    position: relative;
    width: 100%;
    .bg {
      text-align: center;
      width: 100%;
      & > img {
        width: 100%;
      }
    }
    .btn {
      cursor: pointer;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateY(-115%) translateX(-50%);
    }
  }
  .page5 {
    padding-bottom: 100px;
    .bg {
      text-align: center;
      & > img {
        width: 100%;
      }
    }
  }
}
