@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap');

.productView {
  width: 100%;
  padding-top: 70px;

  .buyInfo {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-bottom: 50px;

    .buyInfoContent {
      display: flex;
      justify-content: center;
      width: 80%;

      .picture {
        width: 50%;

        &>img {
          width: 100%;
          border-radius: 17.0667px;
          border-radius: 17.0667px;
        }
      }

      .priceInfo {
        padding-left: 50px;
        padding-right: 50px;

        .text {
          font-family: SF Pro Text;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: #ec7963;
          padding-top: 20px;
        }

        .title {
          font-family: Noto Sans CJK TC;
          font-style: normal;
          font-weight: normal;
          font-size: 27px;
          line-height: 36px;
          color: #242c3f;
        }

        .priceContent {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-top: 15px;
          padding-bottom: 15px;
          width: 100%;

          .price {
            font-family: SF Pro Text;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 31px;
            color: #242c3f;
            padding-right: 7px;
          }

          .originPrice {
            font-family: SF Pro Text;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            text-decoration-line: line-through;
            color: #8b90a0;
            padding-right: 7px;
          }

          .priceOff {
            padding: 4px 10px;
            left: 73.75%;
            right: 19.38%;
            top: 14.63%;
            bottom: 83.35%;

            background: #394f8a;
            border-radius: 4px;
            font-family: SF Pro Text;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            color: #ffffff;
          }
        }

        .buyMessage {
          color: #ff3300;
          text-align: center;
          padding-bottom: 10px;
        }

        .buyBtn {
          cursor: pointer;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 12px 24px;
          left: 18px;
          top: 175px;
          background: #ec7963;
          border-radius: 8px;
          color: white;

          &.disabled {
            background: #7f7a79;
            pointer-events: none;
          }
        }

        .exchangeBtn {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 12px 24px;
          border: 1px solid #d5d7de;
          box-sizing: border-box;
          border-radius: 8px;
          margin-top: 10px;

          &>img {
            padding-right: 10px;
          }
        }

        .noteContent {
          padding: 5px;

          .note1 {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-top: 10px;

            &>img {
              padding-right: 10px;
            }
          }

          .launchTimetable {
            a {
              margin-left: 20px;
              margin-right: 9px;
              font-size: 13px;
              font-weight: 700;
              color: #EC7963;
              text-decoration: none;
            }
          }
        }
      }
    }

    .productItem {
      display: flex;
      justify-content: left;
      width: 72%;
      padding-bottom: 50px;

      &>.row {
        padding-bottom: calc(var(--bs-gutter-x) * 0.5);
      }

      &>.card {
        border: none;
      }

      .readBtn {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        // padding: 12px 24px;
        padding: 5px;
        left: 12px;
        top: 4px;
        background: #ec7963;
        border-radius: 4px;
        color: white;
        font-size: 16px;
      }

      .readDiv {
        align-items: flex-end;
      }
    }
  }

  .bannel {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      width: 800px;
      height: 130px;
      transition: 0.5s;
      overflow: hidden;
      background-image: url('bannel.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      border: none;
    }

    button:nth-child(1) {
      filter: hue-rotate(1deg);
    }

    button:nth-child(2) {
      filter: hue-rotate(1deg);
    }

    button span {
      position: absolute;
      display: block;
    }

    button span:nth-child(1) {
      top: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: linear-gradient(90deg, transparent, #07B2D8);
      animation: animate1 1s linear infinite;
    }

    @keyframes animate1 {
      0% {
        left: -100%;
      }

      50%,
      100% {
        left: 100%;
      }
    }

    button span:nth-child(2) {
      top: -100%;
      right: 0;
      width: 2px;
      height: 100%;
      background: linear-gradient(180deg, transparent, #07B2D8);
      animation: animate2 1s linear infinite;
      animation-delay: 0.25s;
    }

    @keyframes animate2 {
      0% {
        top: -100%;
      }

      50%,
      100% {
        top: 100%;
      }
    }

    button span:nth-child(3) {
      bottom: 0;
      right: 0;
      width: 100%;
      height: 2px;
      background: linear-gradient(270deg, transparent, #07B2D8);
      animation: animate3 1s linear infinite;
      animation-delay: 0.50s;
    }

    @keyframes animate3 {
      0% {
        right: -100%;
      }

      50%,
      100% {
        right: 100%;
      }
    }


    button span:nth-child(4) {
      bottom: -100%;
      left: 0;
      width: 2px;
      height: 100%;
      background: linear-gradient(360deg, transparent, #07B2D8);
      animation: animate4 1s linear infinite;
      animation-delay: 0.75s;
    }

    @keyframes animate4 {
      0% {
        bottom: -100%;
      }

      50%,
      100% {
        bottom: 100%;
      }
    }
  }

  .kinds {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 20px;

    .subjectCombination {
      display: flex;
      justify-content: left;
      width: 75%;

      &>h1 {
        /* Ch/Heading/H1-ch */
        font-family: Noto Sans CJK TC;
        font-style: normal;
        font-weight: normal;
        font-size: 26px;
        line-height: 36px;
        color: #394f8a;
      }
    }
  }

  .yearSelect {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .yearContent {
      width: 80%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-bottom: 15px;

      .naniBtn {
        width: 12%;
        font-size: 19px;
        margin: 8px;
        border-radius: 12px;
        background-color: #ec796300;
        border-color: #ec796300;
        color: #8b90a0;

        &.disabled {
          background-color: #ec7963;
          border-color: #ec7963;
          color: #ffffff;
          opacity: 1;
        }
      }

      .naniBtn:hover {
        background-color: #fdf5f0;
        border-color: #fdf5f0;
        color: #8b90a0;
      }
    }
  }
}