.SelectCustomOrderCode {
  .tabs {
    display: flex;
    height: 610px;
    .leftSelect {
      width: 32%;
      height: 100%;
      overflow: hidden;
      background: #ffffff;
      //box-shadow: 0px 8px 16px "rgb(0 0 0 / 10%)";
      border-radius: 16px;
      border: 1px solid #c9c9c9;
      padding: 15px;
      .searchText {
        border: 1px solid #ccc;
        border-radius: 5px;
        margin: 5px;
      }
      & > div:first-child {
        width: 80%;
      }
      .productList {
        margin-top: 15px;
        & > div {
          padding-left: 10px;
          .listBtn {
            cursor: pointer;
            background-color: #fff;
          }

          .listBtn:hover {
            background-color: #c9c9c9;
          }
        }
      }

      .sendBtn {
        margin-top: 15px;
        & > button {
          color: #ffffff;
          background-color: rgb(38, 125, 255);
          width: 100%;
        }
      }
    }
    .centerSelect {
      width: 32%;
      height: 100%;
      overflow: hidden;
      background: #ffffff;
      border-radius: 16px;
      border: 1px solid #c9c9c9;
      padding: 15px;
      margin-left: 15px;
      .productList {
        height: 86%;
        overflow: auto;
      }

      .sendBtn {
        & > button {
          color: #ffffff;
          background-color: rgb(38, 125, 255);
          width: 100%;
        }
      }
    }
    .rightSelect {
      padding: 0 15px;
      width: 32%;
      .textContent {
        width: 100%;
        & > div {
          width: 100%;
          padding-bottom: 5px;
          font-size: 14px;
          & > div {
            width: 100%;
            & > label {
              color:black;
              font-size: 18px;
            }
            & > div {
              width: 100%;
            }
          }
        }
        .select{
          .text{
            color:black;
            font-size: 13px;
          }
          &>div{
            height: 30px;
            &>div{
              font-size: 13px;
            }
          }
        }
            font-size: 14px;
        & > h5 {
          width: 100%;
          display: flex;
          justify-content: center;
        }

        .totalUse{
          & >div{
            .input{
              display: flex;
              justify-content: start;
              align-items: center;
              padding: 5px 0px 5px 20px;
              &>input{
                margin-right: 5px;
              }
            }
          }
          
        }
      }
      .orderCode{
        margin-top: 10px;
        &>div{
          height: 30px;
          &>div{
            font-size: 13px;
            height: 20px;
          }
        }
      }
      .sendBtn {
        & > button {
          color: #ffffff;
          background-color: rgb(38, 125, 255);
          width: 100%;
        }
      }
      .productArea {
        justify-content: center;
        height: 82%;
        overflow: auto;
        background: #ffffff;
        //box-shadow: 0px 8px 16px "rgb(0 0 0 / 10%)";
        border-radius: 16px;
        border: 1px solid #c9c9c9;
        padding: 15px;
        margin: 0 auto;
        width: 100%;
        margin-bottom: 15px;
        .areaContent {
          height: 100%;
          overflow: auto;
          word-wrap: break-word;
          text-align: start;
        }
      }
    }
    .productList {
      height: 70%;
      overflow: auto;
      .listBtn {
        cursor: pointer;
        background-color: #fff;
      }

      .listBtn:hover {
        background-color: #c9c9c9;
      }
    }
  }
}
