
.myOrderDetails {
    padding-top: 60px;
    margin: 0 auto;
    width: 100%;
    min-width: 320px;

    .mainArea {
        margin:0 auto;
        max-width: 800px;
        background: #FFFFFF;

        .back {
            width: 200px;
            height: 24px;
            left: 32px;
            top: 0px;

            /* Ch/Body/Body5-ch */

            font-family: Noto Sans CJK TC;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            /* identical to box height, or 171% */

            display: flex;
            align-items: center;

            /* Text/06 */

            color: #8B90A0;
            cursor: pointer;
        }
        
        .infos {
            margin: 0 auto;
            padding: 0 5px;
            max-width: 760px;
            width: 100%;

            .title {
                padding-top: 20px;
                /* Heading/CH/H2-ch */
                font-family: Noto Sans CJK TC;
                font-style: normal;
                font-weight: normal;
                font-size: 24px;
                line-height: 36px;
                /* identical to box height, or 150% */


                /* Text/10 */

                color: #242C3F;
            }

            table {
                width: 100%;
                border-collapse:"separate";
                border-spacing:"10px 2px";
                border: 1px solid #D5D7DE;
                box-sizing: border-box;

                tr {
                    /* En/Body/Body5-en */
                    font-family: Open Sans;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 40px;
                    /* identical to box height, or 171% */
                    /* Text/10 */                    
                    color: #242C3F;
                    /* Inside Auto Layout */
                    flex: none;
                    order: 0;
                    flex-grow: 1;
                    margin: 0px 10px;
                    text-align: left;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
                    padding: 8px 0px;
                    background: #FFFFFF;
                }

                .dataKey {
                    /* Ch/Body/Body5-ch-bold */
                    font-family: Noto Sans CJK TC;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    /* identical to box height, or 171% */
                    /* Text/10 */
                    color: #242C3F;
                    margin: 0px 10px;
                    padding-left: 20px;
                    min-width: 120px;
                }

                .dataValue {
                    /* Ch/Body/Body5-ch */
                    font-family: Noto Sans CJK TC;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    /* identical to box height, or 171% */
                    /* Text/07 */
                    color: #454B5C;
                }
            }
        }

        button {
            /* Auto Layout */
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 9px 16px;
            /* Inside Auto Layout */
            flex: none;
            order: 0;
            flex-grow: 0;
            margin: 20px auto;
            /* Brand/02 */
            background: #121232;
            color: #FFFFFF;
            border-radius: 4px;
            /* Ch/Body/Body5-ch */
            font-family: Noto Sans CJK TC;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            /* identical to box height, or 171% */            
            text-align: center;
            width:311px;
            height:48px;

        }
        button:hover {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 9px 16px;

            /* Brand-Hover/02 */
            background: #2A2A47;
            border-radius: 4px;
        }
    }
}