@import "../../style/media";

.userInfoArea {
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 100px;
  
  .loginBtn {
    top: 15px;
    cursor: pointer;
    &.nanimainpage, &.naniclassview, &.naniproductview {
      border: solid 1px white;
      width: 104px;
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #007AFF;
      border-radius: 8px;
      background: rgba(255,255,255,0.8)
    }
    &.naniclassview,&.naniproductview {
      border: solid 1px #007AFF;
    }
  }
  .userInfoContent {
    .userIcon {
      width: 170px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .userBtn {
        cursor: pointer;
        padding: 10px;
        text-align: right;
      }
    }

    .userList {
      position: absolute;
      right: 0px;
      text-align: left;
      width: 240px;
      border-radius: 8px;
      text-align: left;
      box-shadow: 0px 10px 24px rgb(100, 100, 100);
      background: #ffffff;
      & > div {
        cursor: pointer;
        position: static;
        width: 240px;
        height: 48px;
        left: 0px;
        top: 96px;

        flex: none;
        order: 2;
        flex-grow: 0;
        margin: 0px 0px;

        display: flex;
        justify-content: flex-start;
        align-items: center;
        & > a {
          position: static;
          width: 56px;
          height: 24px;
          left: 0px;
          top: calc(50% - 24px / 2);
          font-family: Noto Sans CJK TC;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          /* identical to box height, or 171% */
          /* UI/10 */
          color: #242c3f;

          /* Inside Auto Layout */

          flex: none;
          order: 0;
          flex-grow: 0;
          margin: 0px 30px;
        }
      }
      & > div:hover {
        background-color: #fdf5f0;
        flex: none;
        order: 2;
        flex-grow: 0;
        margin: 0px 0px;
      }

      .name {
        /* Ch/Body/Body5-ch */

        font-family: Noto Sans CJK TC;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */

        /* Text/06 */
        text-decoration: auto;
        color: #8b90a0;
      }
    }
  }
}
