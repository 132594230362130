html {
  scroll-behavior: smooth;
}

.mainPage {
  background: linear-gradient(106.97deg, rgba(255, 234, 196, 0.2) 2.9%, rgba(255, 204, 112, 0.2) 100%);
  display: flex;
  align-items: stretch;

  .mainbg {
    height: 100vh;
    @media (max-width: 1200px) {
      width: 50%;
      object-fit: cover;
      object-position: 50%;
    }
  }

  .loginArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    > h2 {
      font-size: 32px;
      font-weight: 500px;
      color: #4C99A5;
      margin-bottom: 8px;
    }
    > h1 {
      font-size: 20px;
      font-weight: 500;
      color: #94C2C9;
    }
    .loginBTN {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 360px;
      height: 88px;
      border-radius: 8px;
      background: #82B8C0;
      color: white;
      text-decoration: none;
      font-size: 20px;
      font-weight: 500;
      margin-top: 40px;

      &:hover {
        background: #418791;
        box-shadow: 0px 4px 4px rgba(76, 153, 165, 0.25);
      }
      &.disable {
        filter: grayscale(100%);
        pointer-events: none;
      }
      > img {
        width: 52px;
        height: 52px;
        margin-right: 14px;
      }
    }
    .loginBTNAll {
      background-color: #F9C74F;
      &:hover {
        background: #FFAE64;
      }
    }
  }
}