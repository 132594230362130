.videoExamSearch {
  width: 100%;
  height: 100%;
  .topContent {
    display: flex;
    border: 1px solid #e4e7ec;
    box-sizing: border-box;
    border-radius: 24px;
    padding: 3% 3% 0 3%;
    height: 35%;
    margin-bottom: 15px;
    .dateTimePicker {
      padding: 0 3% 0 3%;
      .sendBtn {
        display: flex;
        justify-content: center;
        padding-bottom: 15px;
        & > button {
          color: #ffffff;
          height: 45px;
          background-color: rgb(38, 125, 255);
          width: 236px;
          border-radius: 8px;
          border: 0;
        }
        .uploadBtn {
          color: #ffffff;
          height: 45px;
          background-color: rgb(38, 125, 255);
          width: 236px;
          border-radius: 8px;
          border: 0;
        }
        .uploadInput {
          display: none;
        }
      }
    }
  }
  .bottomContent {
    width: 100%;
    display: flex;
    border: 1px solid #e4e7ec;
    box-sizing: border-box;
    border-radius: 24px;
    padding: 5px 5px 5px 5px;
    height: 35%;
    .videoContent {
      width: 50%;
      height: 100%;
      padding: 10px 10px 10px 10px;
      h5 {
        height: 5%;
      }
      .videoList {
        overflow-y: auto;
        height: 110px;
        border: 1px solid #7c7c7c;
      }
      .sendBtn {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
        & > button {
          margin-right: 10px;
          color: #ffffff;
          background-color: rgb(38, 125, 255);
          width: 150px;
        }
      }
    }
    .examContent {
      width: 50%;
      padding-left: 3%;
      width: 50%;
      height: 100%;
      padding: 10px 10px 10px 10px;
      h5 {
        height: 5%;
      }
      .examList {
        overflow-y: auto;
        height: 110px;
        border: 1px solid #7c7c7c;
      }
      .sendBtn {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
        & > button {
          margin-right: 10px;
          color: #ffffff;
          background-color: rgb(38, 125, 255);
          width: 150px;
        }
      }
      .examList {
      }
    }
  }
}
