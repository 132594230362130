.classSelect {
  position: absolute;
  left: 0px;
  width: 100%;
  background-color: #fdf5f0;
  display: flex;
  justify-content: flex-end;
  top: 60px;
  z-index: 10;
  .left {
    width: 50%;
    padding: 15px;
    height: 500px;
    overflow: auto;
    display: flex;
    justify-content: center;
    .education {
      display: -webkit-inline-box;
      justify-content: flex-end;
      .warning {
        display: flex;
        align-items: center;
        font-weight: bolder;
        color: #8b90a0;
        letter-spacing: 2px;
      }
      .educationT {
        padding-right: 10px;
        & > div > div {
          padding: 5px 5px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .educationEText {
          font-family: Noto Sans CJK TC;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: #8b90a0;
        }
      }
      .educationE {
        width: 20%;
        & > div > div {
          padding: 5px 5px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .educationEText {
          font-family: Noto Sans CJK TC;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: #8b90a0;
        }
      }
      .educationS {
        width: 20%;
        & > div > div {
          padding: 5px 5px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .educationSText {
          font-family: Noto Sans CJK TC;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: #8b90a0;
        }
      }
      .list {
        cursor: pointer;
      }
      .list:hover {
        background-color: #ec7963;
        border-radius: 20px;
        color: #ffffff;
      }
    }
  }
  .centerLine {
    border: 1px solid #fad7d1;
  }
  .right {
    width: 50%;
    height: 500px;
    overflow: auto;
    .itemArea {
      height: 100%;
      padding: 5%;
      flex-wrap: wrap;
      .itemGroup {
        & > h3 {
          font-family: "Noto Sans CJK TC";
          font-style: normal;
          font-size: 24px;
          line-height: 30px;
          color: #242c3f;
        }
        .item {
          padding: 1%;
          cursor: pointer;
          text-align: center;
          display: inline-block;
          & > img {
            //width: 80%;
          }
        }
      }
    }
  }
}
