.SelectOrderCode {
  .tabs {
    display: flex;
    height: 610px;
    .leftSelect {
      width: 25%;
      height: 100%;
      overflow: hidden;
      background: #ffffff;
      //box-shadow: 0px 8px 16px "rgb(0 0 0 / 10%)";
      border-radius: 16px;
      border: 1px solid #c9c9c9;
      padding: 15px;
      .searchText {
        border: 1px solid #ccc;
        border-radius: 5px;
        margin: 5px;
      }
      & > div:first-child {
        width: 80%;
      }
      .productList {
        & > div {
          padding-left: 10px;
          margin-top: 15px;
          .listBtn {
            cursor: pointer;
            background-color: #fff;
          }

          .listBtn:hover {
            background-color: #c9c9c9;
          }
        }
      }

      .sendBtn {
        padding-top: 14px;
        & > button {
          color: #ffffff;
          background-color: rgb(38, 125, 255);
          width: 100%;
        }
      }
    }
    .centerSelect {
      width: 25%;
      height: 100%;
      overflow: hidden;
      background: #ffffff;
      border-radius: 16px;
      border: 1px solid #c9c9c9;
      padding: 15px;
      margin-left: 15px;
      .productList {
        height: 83%;
        overflow: auto;
      }

      .sendBtn {
        margin-top: 15px;
        & > button {
          color: #ffffff;
          background-color: rgb(38, 125, 255);
          width: 100%;
        }
      }
    }
    .rightSelect {
      padding: 5px 20px;
      width: 25%;
      display: flex;

      .textContent {
        width: 100%;
        & > div {
          padding-top: 5px;
          padding-bottom: 5px;
          width: 100%;
          & > div {
            width: 100%;
            padding-bottom: 2px;
            font-size: 14px;
            & > label {
              color:black;
              font-size: 18px;
            }
            & > div {
              height: 20px;
            }
          }
        }

        .select{
          .text{
            color:black;
            font-size: 13px;
          }
          &>div{
            height: 30px;
            &>div{
              font-size: 13px;
            }
          }
        }
        & > h5 {
          width: 100%;
          display: flex;
          justify-content: center;
        }

        .totalUse{
          & >div{
            .input{
              display: flex;
              justify-content: start;
              align-items: center;
              padding: 20px 0px 20px 20px;
              &>input{
                margin-right: 5px;
              }
            }
          }
          
        }
      }
      .sendBtn {
        & > button {
          color: #ffffff;
          background-color: rgb(38, 125, 255);
          width: 100%;
        }
      }
      .sendBtn2 {
        padding-top: 5px;
        & > button {
          color: #ffffff;
          background-color: rgb(38, 125, 255);
          width: 100%;
        }
      }
      .deadline{
        & > div{

          width: 100%;
          & > div{

            width: 100%;
            height: 30px !important;
          }
        }
      }
      .productArea {
        display: flex;
        justify-content: center;
        height: 200px;
        overflow: auto;
        background: #ffffff;
        //box-shadow: 0px 8px 16px "rgb(0 0 0 / 10%)";
        border-radius: 16px;
        border: 1px solid #c9c9c9;
        padding: 15px;
        margin: 0 auto;
        width: 300px;
        margin-bottom: 15px;
        .areaContent {
          height: 100%;
          overflow: auto;
          word-wrap: break-word;
          text-align: start;
        }
      }
    }

    .rightSelect2 {
      padding: 15px;
      width: 25%;

      .textContent {
        & > div {
          padding-bottom: 10px;
          & > div {
            width: 300px;
            & > label {
              font-size: 24px;
            }
          }
        }
        & > h5 {
          width: 100%;
          display: flex;
          justify-content: center;
        }
      }
      .sendBtn {
        & > button {
          color: #ffffff;
          background-color: rgb(38, 125, 255);
          width: 100%;
        }
      }
      .productArea {
        justify-content: center;
        height: 81%;
        overflow: auto;
        background: #ffffff;
        //box-shadow: 0px 8px 16px "rgb(0 0 0 / 10%)";
        border-radius: 16px;
        border: 1px solid #c9c9c9;
        padding: 15px;
        margin: 0 auto;
        width: 100%;
        margin-bottom: 15px;
        .areaContent {
          height: 100%;
          overflow: auto;
          word-wrap: break-word;
          text-align: start;
        }
      }
    }
    .productList {
      height: 70%;
      overflow: auto;
      .listBtn {
        cursor: pointer;
        background-color: #fff;
      }

      .listBtn:hover {
        background-color: #c9c9c9;
      }
    }
  }
}
