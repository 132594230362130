.loginArea {
  position: absolute;
  top: 0px;
  left: 0px;
}

body {
  font-family: Arial, Helvetica, sans-serif;
}

/* Full-width input fields */
.textInput,
.textPassword {
  all: unset;
  background: #f2f4f8;
  max-width: 292px;
  width: 80%;
  padding: 16px 14px;
  border-radius: 8px;
  border: solid 1px rgba(145, 158, 171, 0.32);
  margin-bottom: 24px;
  &::placeholder {
    color: #919eab;
    font-size: 16px;
  }
}
.checkboxInput {
  max-width: 320px;
  width: 80%;
  padding: 11px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #212b36;
  cursor: pointer;
  margin-bottom: 24px;
  .checkMark {
    position: absolute;
    color: white;
    font-weight: 900;
    transform: translateX(2px);
    user-select: none;
  }
  input {
    all: unset;
    width: 18px;
    height: 18px;
    margin-right: 11px;
    border: solid 1px rgba(145, 158, 171, 0.32);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    &::before {
      content: "✓";
      font-weight: 900;
      user-select: none;
      color: white;
    }
    &:checked {
      background-color: #ff6065;
    }
  }
}

/* Set a style for all buttons */
.btnLogin {
  all: unset;
  background: #ff6065;
  max-width: 320px;
  width: 80%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px;
  color: white;
  font-size: 15px;
  font-weight: 700;
  border-radius: 8px;
  cursor: pointer;
  &.disabled {
    cursor: not-allowed;
  }
  &:hover {
    opacity: 0.8;
  }
}

.btnMoe {
  background-color: #83B8C0;
  margin-bottom: 28px;
  &.isLoading{
    background: rgba(0,0,0,0.5);
    cursor: not-allowed;
  }
  &:hover {
    border-color: none;
    color: white;
  }
}

/* Extra styles for the cancel button */
.cancelbtn {
  width: auto;
  padding: 10px 18px;
  background-color: #f44336;
}

/* Center the image and position the close button */
.imgcontainer {
  text-align: center;
  margin: 24px 0 12px 0;
  position: relative;
}

img.avatar {
  width: 40%;
  border-radius: 50%;
}

.container {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 500px;
  h4 {
    color: #454b5c;
    margin-bottom: 32px;
  }
}

span.psw {
  float: right;
  padding-top: 16px;
}

.modal-error {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
  padding-top: 10px;
}

/* The Modal (background) */
.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
  padding-top: 80px;
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 5% auto 15% auto;
  /* 5% from the top, 15% from the bottom and centered */
  border: 1px solid #888;
  width: 80%;
  max-width: 500px;
  /* Could be more or less, depending on screen size */
}

/* The Close Button (x) */
.close {
  position: absolute;
  right: 25px;
  top: 0;
  color: #000;
  font-size: 35px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: red;
  cursor: pointer;
}

/* Add Zoom Animation */
.animate {
  -webkit-animation: animatezoom 0.6s;
  animation: animatezoom 0.6s;
}

@-webkit-keyframes animatezoom {
  from {
    -webkit-transform: scale(0);
  }

  to {
    -webkit-transform: scale(1);
  }
}

@keyframes animatezoom {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

/* Change styles for span and cancel button on extra small screens */
@media screen and (max-width: 300px) {
  span.psw {
    display: block;
    float: none;
  }

  .cancelbtn {
    width: 100%;
  }
}
