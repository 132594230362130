.exportAllReport {
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 16px;

    background: #ffffff;
    border: 1px solid #ff6065;
    border-radius: 8px;
    .content {
        display: flex;
        font-family: "Noto Sans TC";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */

        display: flex;
        align-items: center;
        text-align: center;

        color: #ff6065;
        & > img {
            padding-right: 6px;
        }
    }
}
