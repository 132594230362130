.rule {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
  & > div {
    width: 100%;
    background: #fdf5f0;
    border-radius: 24px;
    text-align: center;
    padding: 10px 15px;
    & > .content {
      text-align: left;
      .caption {
        font-family: Noto Sans CJK TC;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        color: #242c3f;
        padding: 25px 0 13px;
      }
      .systemRuleContent {
        border: 1px solid #000000;
        box-sizing: border-box;
        width: 100%;
        margin-bottom: 13px;
        .top {
          width: 100%;
          display: flex;
          .left {
            width: 40%;

            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 10px;

            border-right: 1px solid black;
            border-bottom: 1px solid black;
          }
          .right {
            width: 60%;
            padding: 10px;
            border-bottom: 1px solid black;
          }
        }
        .center {
          width: 100%;
          display: flex;
          .left {
            width: 40%;

            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 10px;
            border-right: 1px solid black;
            border-bottom: 1px solid black;
          }
          .right {
            width: 60%;
            padding: 10px;
            border-bottom: 1px solid black;
          }
        }
        .bottom {
          width: 100%;
          display: flex;
          .left {
            width: 40%;

            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 10px;
            border-right: 1px solid black;
          }
          .right {
            width: 60%;
            padding: 10px;
          }
        }
      }
    }
  }
}

.ok {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  & > div {
    cursor: pointer;
    text-align: center;
    background: #394f8a;
    border-radius: 8px;
    width: 100px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
