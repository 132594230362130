.backContent {
  padding-top: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  .back {
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 80%;
    font-family: Noto Sans CJK TC;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    display: flex;
    align-items: center;

    /* Text/06 */

    color: #8b90a0;
  }
}

.serviceView {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 70px;
  & > div {
    width: 80%;
    background: #fdf5f0;
    border-radius: 24px;
    text-align: center;
    padding: 40px 40px;
    .title {
      font-family: Noto Sans CJK TC;
      font-style: normal;
      font-weight: normal;
      font-size: 32px;
      line-height: 36px;
      /* identical to box height, or 112% */

      /* Blue 01 */

      color: #394f8a;
    }
  }
  .content {
    text-align: left;
    .caption {
      font-family: Noto Sans CJK TC;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
      color: #242c3f;
      padding: 25px 0 13px;
    }
    .systemRuleContent {
      border: 1px solid #000000;
      box-sizing: border-box;
      width: 100%;
      margin-bottom: 13px;
      .top {
        width: 100%;
        display: flex;
        .left {
          width: 40%;

          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 10px;

          border-right: 1px solid black;
          border-bottom: 1px solid black;
        }
        .right{
          width: 60%;
          padding: 10px;
          border-bottom: 1px solid black;
        }
      }
      .center {
        width: 100%;
        display: flex;
        .left {
          width: 40%;

          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 10px;
          border-right: 1px solid black;
          border-bottom: 1px solid black;
        }
        .right{
          width: 60%;
          padding: 10px;
          border-bottom: 1px solid black;
        }
      }
      .bottom {
        width: 100%;
        display: flex;
        .left {
          width: 40%;

          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 10px;
          border-right: 1px solid black;
        }
        .right{
          width: 60%;
          padding: 10px;
        }
      }
    }
  }
}
