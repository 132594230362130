.CreateDiscount {
  .tabs {
    display: flex;
    height: 550px;
    .leftSelect {
      width: 50%;
      height: 100%;
      overflow: hidden;
      background: #ffffff;
      //box-shadow: 0px 8px 16px "rgb(0 0 0 / 10%)";
      border-radius: 16px;
      border: 1px solid #c9c9c9;
      padding: 15px;

      .discountName {
        display: flex;
        margin-top: 15px;
        margin-bottom: 15px;
        .name {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-right: 20px;
        }
        .nameInput {
          width: 80%;
          border: 1px solid #4c4c4c;
        }
      }

      .discountType {
        height: 300px;
        overflow: hidden;

        & > div {
          .customDiscount {
            & > div {
              & > .nameInput {
                width: 60%;
                border: 1px solid #4c4c4c;
              }
              & .hide {
                filter: opacity(0.3);
                pointer-events: none;
              }
              & > .useCount {
                border: 1px solid #4c4c4c;
                width: 2%;
              }
            }
            .customUseCount {
              padding-left: 50px;
              & > div {
                & > .nameInput {
                  border: 1px solid #4c4c4c;
                }
                & > div {
                  & > .useCount {
                    border: 1px solid #4c4c4c;
                    width: 20%;
                  }
                }
              }
            }
            & .hide {
              filter: opacity(0.3);
              pointer-events: none;
            }
          }
          .autoDiscount {
            .autoCreate {
              padding-left: 50px;
              .autoFristText {
                margin-bottom: 15px;
              }
              & > div {
                & > .strInput {
                  border: 1px solid #4c4c4c;
                }
                & > .useCount {
                  border: 1px solid #4c4c4c;
                  width: 20%;
                }
              }
            }
            & .hide {
              filter: opacity(0.3);
              pointer-events: none;
            }
          }
        }
      }

      .searchText {
        border: 1px solid #ccc;
        border-radius: 5px;
        margin: 5px;
      }
      & > div:first-child {
        width: 80%;
      }
      .productList {
        margin-top: 15px;
        & > div {
          padding-left: 10px;
          .listBtn {
            cursor: pointer;
            background-color: #fff;
          }

          .listBtn:hover {
            background-color: #c9c9c9;
          }
        }
      }

      .sendBtn {
        margin-top: 15px;
        display: flex;
        justify-content: center;
        & > button {
          margin-right: 10px;
          color: #ffffff;
          background-color: rgb(38, 125, 255);
          width: 120px;
        }
      }
    }
    .centerSelect {
      width: 50%;
      height: 100%;
      overflow: hidden;
      background: #ffffff;
      border-radius: 16px;
      border: 1px solid #c9c9c9;
      padding: 15px;
      margin-left: 15px;
      .plan {
        padding-bottom: 15px;
        width: 100%;
        & > div {
          width: 100%;
          & > div {
            width: 100%;
            & > label {
              width: 25%;
            }
            & > input {
              border: 1px solid #4c4c4c;
              width: 60%;
            }
            & .hide {
              filter: opacity(0.3);
              pointer-events: none;
            }
          }
        }
      }
      .factor {
        & > div {
          & > input {
            border: 1px solid #4c4c4c;
          }
        }
        .selectTimeTitle {
          margin-top: 10px;
          margin-bottom: 10px;
        }
        .selectTime {
          display: flex;
          margin: 0 auto;
          .dateBtn {
            padding-right: 10px;
            margin-bottom: 15px;
            & > button {
              margin-right: 10px;
              color: #ffffff;
              background-color: rgb(38, 125, 255);
              width: 120px;
            }
          }
        }
        .sendBtn {
          margin-bottom: 15px;
          display: flex;
          justify-content: end;
          & > button {
            margin-right: 10px;
            color: #ffffff;
            background-color: rgb(38, 125, 255);
            width: 120px;
          }
        }
      }
      .exportCSV {
        .sendBtn {
          width: 100%;
          margin-bottom: 15px;
          display: flex;
          justify-content: end;
          & > button {
            margin-right: 10px;
            color: #ffffff;
            background-color: rgb(38, 125, 255);
            width: 120px;
          }
        }
      }
    }
  }
}
