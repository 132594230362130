.videoExamSearch {
  background-color: #eeeeee;
  overflow: hidden;
  .tabControl {
    box-sizing: border-box;
    margin: 32px 40px 61px 40px;
    background: white;
    .secondTypeContent {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      background: #ffffff;

      .secondTypeBorder {
        display: flex;
        margin: 1rem 24px;
        border: 1px solid rgba(145, 158, 171, 0.12);
        border-radius: 8px;
        box-sizing: border-box;
        padding: 2px 0;
        .secondTab {
          cursor: pointer;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 26px;
          display: flex;
          align-items: center;
          color: #637381;
          margin: 2px 8px;
          padding: 12px;

          &.show {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 26px;
            /* identical to box height, or 186% */

            display: flex;
            align-items: center;

            /* 01 Primary/03 Main */

            color: #ec7963;

            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 12px;

            background: rgba(236, 121, 99, 0.08);
            border-radius: 8px;
          }
        }
      }
    }
  }
  .topContent {
    display: flex;
    box-sizing: border-box;
    border-radius: 24px;
    padding: 3% 3% 0 3%;
    height: 35%;
    margin-bottom: 15px;
    .dateTimePicker {
      padding: 0 3% 0 3%;
      .sendBtn {
        display: flex;
        justify-content: center;
        padding-bottom: 15px;
        & > button {
          color: #ffffff;
          height: 45px;
          background-color: rgb(38, 125, 255);
          width: 236px;
          border-radius: 8px;
          border: 0;
        }
        .uploadBtn {
          color: #ffffff;
          height: 45px;
          background-color: rgb(38, 125, 255);
          width: 236px;
          border-radius: 8px;
          border: 0;
        }
        .uploadInput {
          display: none;
        }
      }
    }
  }
  .bottomContent {
    width: 100%;
    display: flex;
    box-sizing: border-box;
    border-radius: 24px;
    padding: 5px 5px 5px 5px;
    height: 35%;
    .videoContent {
      width: 50%;
      height: 100%;
      padding: 10px 10px 10px 10px;
      h5 {
        height: 5%;
      }
      .videoList {
        overflow-y: auto;
        height: 110px;
        border: 1px solid #7c7c7c;
      }
      .sendBtn {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
        & > button {
          margin-right: 10px;
          color: #ffffff;
          background-color: rgb(38, 125, 255);
          width: 150px;
        }
      }
    }
    .examContent {
      width: 50%;
      padding-left: 3%;
      width: 50%;
      height: 100%;
      padding: 10px 10px 10px 10px;
      h5 {
        height: 5%;
      }
      .examList {
        overflow-y: auto;
        height: 110px;
        border: 1px solid #7c7c7c;
      }
      .sendBtn {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
        & > button {
          margin-right: 10px;
          color: #ffffff;
          background-color: rgb(38, 125, 255);
          width: 150px;
        }
      }
    }
  }
}

.tabs {
  display: flex;
  list-style: none;
  font-weight: 500;
  line-height: 27px;
  align-items: center;
  width: 70%;
  li {
    text-align: center;
    margin: 0 32px;
    padding: 11px 0px;
    color: #637381;
    cursor: pointer;
  }
  .currentTab {
    border-bottom: solid 1px #ff6065;
    color: #ff6065;
  }
}

.searchConditionControl {
  width: 1100px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}

.searchBtn {
  width: 88px;
  height: 36px;
  background-color: #ff6065;
  border-radius: 8px;
  border: none;
  color: white;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
}

.reportTitle {
  display: flex;
  width: 100%;
  padding: 12px;
  .reportInfo {
    font-family: "Noto Sans TC";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    width: 89%;
    display: flex;
    align-items: center;
    .report {
      padding-left: 10px;
      display: flex;
      justify-content: center;
      padding-right: 5px;
    }
    .yymmdd {
      display: flex;
      justify-content: center;
      color: #919eab;
    }
  }
  .reportInfoExport {
    color: #454b5c;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 116px;
    height: 36px;
    border: 1px solid #454b5c7a;
    background: white;
    border-radius: 8px;
    font-weight: 700;
    width: 10%;
  }
}

.exportBtn {
  color: #454b5c;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 116px;
  height: 36px;
  border: 1px solid #454b5c7a;
  background: white;
  border-radius: 8px;
  font-weight: 700;
}

.roundedInput,
.roundedInput fieldset {
  border-radius: 8px;
}

.infoNumControl {
  font-size: 14px;
  display: flex;
  padding: 17px 22px;
  justify-content: flex-end;
  align-items: center;
  border-top: solid 1px rgba(0, 0, 0, 0.05);
  select {
    border: none;
    margin-left: 15px;
  }
  .totalNum {
    margin: 0 40px 0 30px;
  }
}
