.loadWrapper {
  padding: 5%;
  & > div {
    display: block;
  }
  .recodeList {
    margin-bottom: 20px;
    .list {
      display: flex;
      width: 100%;
      .ant-skeleton-element {
        width: none;
      }

      .left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-right: 20px;
        width: 80%;
      }
      .right {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 20px;
      }
    }
  }
  .ant-skeleton-element {
    display: block;
  }
}
