.head {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 70px;

  z-index: 1000;
  background-color: transparent;
  transition: background-color 0.4s linear;

  display: flex;
  align-items: center;

  &.show {
    background-color: white;
    transition: background-color 0.4s linear;
  }

  .logoBox {
    cursor: pointer;
    padding: 10px;
    height: 100%;
    max-height: 70px;
  }

  .nanilogoBox {
    cursor: pointer;
    padding: 10px;
    height: 100%;
    max-height: 65px;
  }

  .versionNum {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex: 0;
    color: #ec7963;
    font-size: 0.8rem;

    .versionNumText {
      margin-bottom: 3px;

      &.show {
        color: white;
      }
    }
  }
}



.selectSubject {
  /* Rectangle 1797 */
  position: absolute;
  padding: 10px;
  top: 10px;
  /* Beige 01 */
  // background: #fdf5f0;
  border: 1px solid #EC7963;
  border-radius: 8px;
  color: #EC7963;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &.nanipage {
    .selectButton {
      font-weight: bolder;
    }
  }
  .selectButton {
    margin: 0px 10px;
  }


  &.show {
    background: #fdf5f0;
    padding-bottom: 20px;
    border: none;
  }

  // background-color: #fdf5f0;
  & > div:hover {
    // background: #fdf5f0;
    // background-color: #fdf5f0;
  }

  & > div {
    cursor: pointer;
  }

  .nowListText {
    display: flex;
    align-items: center;
  }
}

.bigLearnerBtn {
  width: 164px;
  height: 60px;
  border: none;
  cursor: pointer;
  flex-shrink: 0;
  &:hover {
    transform: scale(1.09);
  }
}

.launchTimetable {
  position: absolute;
  top: 70px;
  z-index: 333;
  width: 100%;
  padding-right: 0.7rem;

  .launchTimetableText {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 10%;

    svg {
      vertical-align: middle;
      margin-right: 25px;
    }

    span {
      color: white;
      vertical-align: middle;

      &:first-of-type {
        font-weight: 700;
        font-size: 20px;
        text-shadow: 0px 0px 15px #0071c4;
        margin-right: 16px;
      }

      &:last-of-type {
        font-weight: 700;
        font-size: 14px;
      }
    }
  }

  img {
    object-fit: cover;
    height: 68px;
    width: 100%;
    object-position: right center;
  }

  .timetableLink {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 11%;
    background: white;
    border-radius: 4px;
    padding: 0.5% 1%;
    color: black;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
  }

  .closeBtn {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 8%;
    max-width: 80px;
    cursor: pointer;
  }
}
