.SearchOrder {
  .exportContent {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 5px;

    .exportBtn {
      width: 100px;
      color: #ffffff;
      height: 45px;
      background-color: rgb(38, 125, 255);
      /* width: 236px; */
      border-radius: 8px;
      border: 0;
    }
  }

  .tabs {
    display: flex;
    height: 550px;

    .leftSelect {
      width: 38%;
      height: 100%;
      overflow: hidden;
      background: #ffffff;
      //box-shadow: 0px 8px 16px "rgb(0 0 0 / 10%)";
      border-radius: 16px;
      border: 1px solid #c9c9c9;
      padding: 15px;

      .accountsProductList {
        height: 300px;
        overflow: hidden;
        border: 1px solid #8c8c8c;

        &>div {
          height: 100%;
          overflow: auto;

          .listName {
            &>h5 {
              font-weight: 700;
            }
          }
        }
      }

      .searchText {
        border: 1px solid #ccc;
        border-radius: 5px;
        margin: 5px;
      }

      &>div:first-child {
        width: 80%;
      }

      .productList {
        margin-top: 15px;

        &>div {
          padding-left: 10px;

          .listBtn {
            cursor: pointer;
            background-color: #fff;
          }

          .listBtn:hover {
            background-color: #c9c9c9;
          }
        }
      }

      .sendBtn {
        margin-top: 15px;
        display: flex;
        justify-content: center;

        &>button {
          margin-right: 10px;
          color: #ffffff;
          background-color: rgb(38, 125, 255);
          width: 120px;
        }
      }
    }

    .centerSelect {
      width: 38%;
      height: 100%;
      overflow: hidden;
      background: #ffffff;
      border-radius: 16px;
      border: 1px solid #c9c9c9;
      padding: 15px;
      margin-left: 15px;

      .consumedProductList {
        height: 300px;
        overflow: hidden;
        border: 1px solid #8c8c8c;

        &>div {
          height: 100%;
          overflow: auto;

          .listName {
            &>h5 {
              font-weight: 700;
            }
          }
        }
      }

      .productList {
        height: 86%;
        overflow: auto;
      }

      .sendBtn {
        margin-top: 15px;
        display: flex;
        justify-content: center;

        &>button {
          margin-right: 10px;
          color: #ffffff;
          background-color: rgb(38, 125, 255);
          width: 120px;
        }
      }
    }

    .rightSelect {
      padding: 15px;
      text-align: center;
      width: 30%;

      .textContent {
        padding-bottom: 30px;

        &>div {
          padding-bottom: 10px;

          &>div {
            width: 300px;

            &>label {
              font-size: 24px;
            }
          }
        }

        &>h5 {
          width: 100%;
          display: flex;
          justify-content: flex-start;
        }
      }

      .sendBtn {
        padding-top: 15px;

        &>button {
          color: #ffffff;
          background-color: rgb(38, 125, 255);
          width: 300px;
        }
      }

      .productArea {
        display: flex;
        justify-content: center;
        height: 200px;
        overflow: auto;
        background: #ffffff;
        //box-shadow: 0px 8px 16px "rgb(0 0 0 / 10%)";
        border-radius: 16px;
        border: 1px solid #c9c9c9;
        padding: 15px;
        margin: 0 auto;
        width: 300px;
        margin-bottom: 15px;

        .areaContent {
          height: 100%;
          overflow: auto;
          word-wrap: break-word;
          text-align: start;
        }
      }
    }

    .productList {
      height: 70%;
      overflow: auto;

      .listBtn {
        cursor: pointer;
        background-color: #fff;
      }

      .listBtn:hover {
        background-color: #c9c9c9;
      }
    }
  }
}