.studyNode {
  margin: 0 auto;
  width: 100%;
  text-align: center;
  padding: 5.5%;
  .classImage {
    width: 100%;
    padding-bottom: 3%;
    & > img {
      width: 100%;
    }
  }
  .courseText {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 10px;
    margin-left: 10px;
  }
  .titleText {
    display: flex;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 3%;
    .studyText {
      width: 70%;
      & > div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-family: Noto Sans CJK TC;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: #242c3f;

        .circle {
          width: 8px;
          height: 8px;
          background: #ec7963;
          border-radius: 50%;
          margin-right: 10px;
        }
      }
    }

    .searchBtn {
      width: 30%;
      height: 40px;
      left: 0px;
      top: 0px;
      background: #ffffff;
      border: 1px solid #d5d7de;
      box-sizing: border-box;
      border-radius: 12px;
      display: flex;
      .left {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        background: #394f8a;
        border-radius: 12px 0px 0px 12px;
      }
      .right {
        width: 50%;
        display: flex;
        color: #a1a4b1;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .total {
    width: 100%;
    padding-bottom: 3%;
    .totalArea {
      border: 1px solid #e4e7ec;
      box-sizing: border-box;
      border-radius: 24px;
      padding: 3%;
      .viewText {
        display: flex;
        & > img {
          padding-left: 20px;
          padding-right: 10px;
        }
      }
      .video {
        display: flex;
        width: 100%;
        .text {
          width: 20%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .playCountContent {
          width: 218px;
          height: 106px;
          left: 92px;
          top: 0px;

          margin: 20px;
          background: #fdf5f0;
          border-radius: 16px;
          display: flex;
          align-items: center;
          justify-content: center;

          .playCountText {
            color: #ec7963;
          }
        }
        .minuteCountContent {
          width: 218px;
          height: 106px;
          left: 92px;
          top: 0px;

          margin: 20px;
          background: #fdf5f0;
          border-radius: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          .minuteCountText {
            color: #ec7963;
          }
        }
      }
    }
  }
}
