.btnsContent {
  width: 100%;
  .naniBtn {
    width: 27%;
    font-size: 22px;
    margin: 5px;
    border-radius: 12px;
    background-color: #ec796300;
    border-color: #ec796300;
    color: #8b90a0;
    &.disabled {
      background-color: #ec7963;
      border-color: #ec7963;
      color: #ffffff;
      opacity: 1;
    }
  }
  .naniBtn:hover {
    background-color: #fdf5f0;
    border-color: #fdf5f0;
    color: #8b90a0;
  }
}

.leftPannelTitle {
  height: 20%;
  padding: 5%;

  .back {
    & > svg {
      height: 18px;
    }
    & > div {
      padding-left: 10px;
    }
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 20px;
    font-family: Noto Sans CJK TC;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #8b90a0;
  }
  .title {
    position: static;
    width: 100%;
    left: 0px;
    top: 12px;
    font-family: Noto Sans CJK TC;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 36px;
    color: #242c3f;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 10px;
    padding-bottom: 20px;
  }
  .content {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 16px 8px 8px;

    position: static;
    width: 216px;
    height: 40px;
    left: 0px;
    top: 112px;

    /* Inside Auto Layout */

    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
    margin: 0px;
    .vector {
      position: static;
      width: 24px;
      height: 24px;
      left: 8px;
      top: 8px;
      flex: none;
      order: 0;
      flex-grow: 0;
      margin: 0px 10px;
    }

    .text {
      position: static;
    }
  }
  .line {
    height: 1px;
    left: 0px;
    top: 164px;
    background: #f2f4f8;
    margin: 10px 0 10px 0px;
  }
}

.tree1 {
  color: #394f8a;
  position: inherit;
  box-shadow: none;
  & > div {
    & > h3 {
      font-family: Noto Sans CJK TC;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;

      color: #394f8a;
    }
    & > h3:hover {
      color: #ec7963;
    }
    & > div {
      cursor: pointer;
      font-family: Noto Sans CJK TC;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;

      color: #394f8a;
      & > div {
        //margin: 10px;
        padding: 5px;
      }
    }
    & > div:hover {
      color: #ec7963;
    }
  }

  .titles {
    display: flex;
  }
}

.tree2 {
  .item2 {
    border-radius: 12px;
    background: #fdf5f0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 15px;
    & > h3 {
      width: 100%;
      padding: 12px 16px;
      font-family: Noto Sans CJK TC;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      color: #3a4052;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    & > .test {
      width: 100%;
      padding: 12px 16px;
      font-family: Noto Sans CJK TC;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      color: #3a4052;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    & > div {
      width: 100%;
      & > div {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 8px 0px 8px 16px;
        background: #ffffff;
        border-radius: 8px;
        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 12px 20px;
        font-family: Noto Sans CJK TC;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: #3a4052;
        & > div:hover {
          color: #ec7963;
        }
      }
    }
  }
}
