
.PurchaseFailed {
    padding-top: 60px;
    margin: 0 auto;
    width: 100%;
    min-width: 320px;

    .mainArea {
        margin:0 auto;
        background: #FFFFFF;
        text-align: center;
    
        .btnBack {
            /* Auto Layout */
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 9px 16px;
            /* Inside Auto Layout */
            flex: none;
            order: 0;
            flex-grow: 0;
            margin: 10px auto;
            /* Brand/02 */
            background: #121232;
            color: #FFFFFF;
            border-radius: 4px;
            /* Ch/Body/Body5-ch */
            font-family: Noto Sans CJK TC;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            /* identical to box height, or 171% */            
            text-align: center;
            width:311px;
            height:48px;

        }
        .btnBack:hover {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 9px 16px;

            /* Brand-Hover/02 */
            background: #2A2A47;
            border-radius: 4px;
        }
    }
}