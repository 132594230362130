.exchangeContent {
  background: #ffffff;
  border-radius: 12px;

  @media (min-width: 600px) {
    width: 560px;
  }
  .titleText {
    padding: 28px;
    & > div {
      font-family: Noto Sans CJK TC;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 36px;
      color: #505565;
    }
  }
  .close {
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .inputContent {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    &.hide {
      display: none;
    }
    .inputs {
      width: 48px;
      height: 48px;
      background: #f2f4f8;
      //border: 1px solid #4e4e5a;
      box-sizing: border-box;
      border-radius: 8px;
      margin: 6px;

      &.wrong {
        border: 1px solid #f44336;
      }
      & > input {
        width: 48px;
        height: 100%;
        text-align: center;
        background-color: transparent;
        border: 0;
      }
    }
  }
  .inputMessage {
    font-family: Noto Sans CJK TC;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;

    .hint {
      background: #fafafc;
      border-radius: 8px;

      height: 50px;
      margin: 15px;
      padding: 40px 20px 40px 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      & > img {
        padding-right: 20px;
      }
    }
    &.right {
      color: #505565;
    }
    &.wrong {
      color: #f44336;
    }
  }
  .productList {
    padding-right: 30px;
    padding-left: 30px;
    padding-bottom: 20px;
    .list {
      font-family: Noto Sans CJK TC;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;

      color: #8b90a0;
    }
  }
}

.exchangeBtnsContent {
  display: flex;
  justify-content: flex-end;
  padding-top: 40px;
  padding-bottom: 10px;
  .onClass {
    background: #121232;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 9px 16px;
    width: 100px;
    height: 40px;
  }
  .exchangeBtns {
    display: flex;
    justify-content: flex-end;
    .cancel {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 9px 16px;
      width: 100px;
      height: 40px;
      background: #fafafc;
      border-radius: 4px;
      margin-right: 15px;
    }
    .ok {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 9px 16px;
      width: 100px;
      height: 40px;
      background: #fafafc;
      border-radius: 4px;
    }
    .ok2 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 9px 16px;
      width: 100px;
      height: 40px;
      background: #F2F4F8;
      border-radius: 4px;
    }
  }
}
