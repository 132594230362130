.videoListContent {
    padding: 4%;
    width: 100%;
    background: #ffffff;
    border: 1px solid #e4e7ec;
    box-sizing: border-box;
    border-radius: 24px;
    .videoPlayContent {
        width: 100%;
        height: 50%;
        background-color: #454b5c;
        .videoPlay {
            height: 342px;
            display: flex;
            justify-content: center;
        }
    }
    .OpenLinkContentTitle {
        height: 10%;
        font-family: Noto Sans CJK TC;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #3a4052;
        margin-bottom: 20px;
    }

    .listContent {
        height: 90%;
        .list {
            display: flex;
            width: 100%;
            padding: 25px 20px 25px 20px;
            background: #fdf5f0;
            border-radius: 32px;
            margin-bottom: 20px;
            .icon {
                width: 15%;
                background: #ffffff;
                border-radius: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                & > img {
                    width: 90%;
                    height: 90%;
                }
            }
            .text {
                width: 65%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding-left: 25px;
                .content {
                    .text1 {
                        font-family: Noto Sans CJK TC;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 27px;
                        color: #242c3f;
                    }
                    .text2 {
                        font-family: Noto Sans CJK TC;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 24px;
                        color: #a1a4b1;
                    }
                }
            }
            .testBtn {
                width: 17%;
                display: flex;
                justify-content: center;
                align-items: center;
                pointer-events: auto;
                cursor: pointer;
                & > div {
                    width: 200px;
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #fff;
                    background-color: #3a4f8a;
                    border-radius: 10px;
                }
                &.hide {
                    pointer-events: none;
                    filter: grayscale(100%);
                }
            }
        }
    }
}
