.button {
  width: 9vh;
  min-width: 9vh;
  height: auto;
  font-size: 2vmin;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  padding: 5px 0;
  margin: 5px 3px;
  border: 0;
  border-radius: 5px;
  color: #fff;
  outline: none;
  cursor: pointer;
  // white-space: nowrap;
  // box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);

  a {
    text-decoration: none;
    color: #fff;
  }

  &:focus {
    background-color: #aaa;
    color: #444;
  }
}

.button:hover {
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
}


