.classView {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .classContent {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding-top: 70px;
    padding-left: 30px;
    padding-right: 30px;
    .leftPannel {
      height: 100%;
      width: 30%;
      overflow-y: auto;
      padding: 20px;
      border-right-style: solid;
      border-right-color: #e4e7ec;
      border-width: thin;
    }
    .videoContent {
      height: 100%;
      width: 70%;
      overflow-y: auto;
      margin: 20px;
      //padding: 3.5%;
    }
  }
}
