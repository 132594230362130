@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.message {
  color: #ff6700;
  font-family: Noto Sans CJK TC;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
}

.load {
  margin: 50px;
  .ring {
    width: 10px;
    height: 10px;
    margin: 0 auto;
    padding: 10px;
    border: 3px dashed #ff6700;
    border-radius: 100%;
    animation: loading 1.5s linear infinite;
  }
}
