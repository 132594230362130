.chiayiHead {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 70px;
  z-index: 1000;
  background-color: white;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;

  .logoBox {
    cursor: pointer;
    height: 100%;
    max-height: 70px;
  }

  .nanilogoBox {
    cursor: pointer;
    padding: 10px;
    height: 100%;
    max-height: 50px;
  }

  .versionNum {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex: 0;
    color: #4d99a5;
    font-size: .8rem;
    padding-right: 5px;

    .versionNumText {
      margin-bottom: 3px;
    }
  }
}

.selectButton {
  margin-left: 23px;

  &+.logo {
    margin-left: 9px;
  }
}

.selectSubject {
  width: 120px;
  height: 44px;
  top: 0px;
  /* Beige 01 */
  // background: #fdf5f0;
  border: 1px solid #4d99a5;
  border-radius: 8px;
  color: #4d99a5;
  display: flex;
  align-items: center;

  &.show {
    background: #EEF5F6;
    border: none ;
  }

  &>div {
    cursor: pointer;
  }
}

.login {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  width: 100%;
  .selectButton {
    margin-left: 8px;
    min-width: 60px;
  }

  &>img,
  &>div {
    cursor: pointer;
    user-select: none;
  }
}

.logoutDropdown {
  display: none;
  position: absolute;
  border-radius: 12px;
  background: white;
  width: 100%;
  bottom: -70px;
  right: -10px;
  padding: 8px;
  box-shadow: 0px 0px 30px -10px grey;

  &.show{
    display: block;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0px;
    right: 14px;
    width: 10px;
    height: 10px;
    background: white;
    transform: translateY(-50%) rotate(45deg);
    z-index: 0;
  }

  &>div {
    height: 40px;
    line-height: 24px;
    border-radius: 8px;
    padding: 8px;
    font-size: 14px;

    &:hover {
      background: #F2F4F8;
    }
  }
}


.bigLearnerBtn {
  width: 164px;
  height: 60px;
  border: none;
  cursor: pointer;
  flex-shrink: 0;
  &:hover {
    transform: scale(1.09);
  }
}


.launchTimetable {
  position: absolute;
  top: 70px;
  z-index: 333;
  width: 100%;

  .launchTimetableText {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 10%;

    svg {
      vertical-align: middle;
      margin-right: 25px;
    }

    span {
      color: white;
      vertical-align: middle;

      &:first-of-type {
        font-weight: 700;
        font-size: 20px;
        text-shadow: 0px 0px 15px #0071C4;
        margin-right: 16px;
      }

      &:last-of-type {
        font-weight: 700;
        font-size: 14px;
      }
    }
  }

  img {
    object-fit: cover;
    height: 68px;
    width: 100%;
    object-position: right center;
  }

  .timetableLink {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 11%;
    background: white;
    border-radius: 4px;
    padding: .5% 1%;
    color: black;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
  }

  .closeBtn {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 8%;
    max-width: 80px;
    cursor: pointer;
  }
}
