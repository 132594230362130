@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loadWrapper {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .content {
    width: 300px;
    height: 150px;
    background-color: white;
    z-index: 1000;
    position: absolute;
    border-radius: 5px;
  }
  .bg {
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.4;
    position: absolute;
    top: 0px;
    left: 0px;
  }
}
.message {
  color: #ff6700;
  font-family: Noto Sans CJK TC;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  margin: 20px;
}

.load {
  margin: 30px;
  .ring {
    width: 10px;
    height: 10px;
    margin: 0 auto;
    padding: 10px;
    border: 3px dashed #ff6700;
    border-radius: 100%;
    animation: loading 1.5s linear infinite;
  }
}
