.page1 {
  position: relative;
  height: 1024px;
  .bg {
    position: relative;
    text-align: center;
    height: 100%;
    .home1 {
      position: absolute;
      width: 100%;
      height: 400px;
      left: -50%;
      top: -200px;
    }
    .title {
      text-align: left;
      position: absolute;
      width: 439px;
      height: 100%;
      left: 12%;
      top: 33%;
      z-index: 100;
      .title1 {
        font-family: Noto Sans CJK TC;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 99.33%;
        letter-spacing: -0.025em;
        color: #ec7963;
      }
      .title2 {
        font-family: Noto Sans CJK TC;
        font-style: normal;
        font-weight: bold;
        font-size: 56px;
        line-height: 130%;
        letter-spacing: -0.025em;
      }
      .title3 {
        font-family: Noto Sans CJK TC;
        font-style: normal;
        font-weight: bold;
        font-size: 56px;
        line-height: 130%;
        letter-spacing: -0.025em;
        padding-bottom: 18%;
      }
      .btn{
        cursor: pointer;
      }
    }
    .home2 {
      position: absolute;
      width: 100%;
      height: 1220px;
      right: -50%;
      top: -98px;
    }
  }
}
