@import "../../style/media";

footer {
  width: 100%;
  height: 150px;
  background-color: #fdf5f0;
  padding-top: 3%;
  display: flex;
  justify-content: center;
  & > div {
    display: flex;
    justify-content: center;
    width: 90%;
    height: 20%;
    .linkTextContent {
      display: flex;
      justify-content: center;
      width: 28%;
      .linkText {
        cursor: pointer;
        font-family: Noto Sans CJK TC;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        text-align: right;
        color: #3a4052;
        padding: 0 3%;
      }
    }

    .copyright {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;

      color: #8b90a0;
      display: flex;
      justify-content: center;
      width: 34%;
    }

    .fblineIcons {
      cursor: pointer;
      display: flex;
      justify-content: center;
      width: 28%;
      & > div {
        padding: 0 3%;
      }
    }
  }
}
