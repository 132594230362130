.purchaseListView {
  width: 100%;
  position: absolute;
  top: 60px;
  .treeContent {
    margin-left: 16%;
    margin-right: 16%;
    .myClass {
      font-family: Noto Sans CJK TC;
      font-style: normal;
      font-weight: normal;
      font-size: 32px;
      line-height: 36px;
      color: #242c3f;
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .tree1 {
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      & > div > .itemTitle {
        cursor: pointer;
        background: #fdf5f0;
        border-radius: 8px;
        height: 46px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 10px;
        font-family: Noto Sans CJK TC;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 28px;
        color: #242c3f;
      }
      &.show {
        background: #fdf5f0;
        box-shadow: none;
      }
      .classContent {
        & > div > div {
          display: flex;
          flex-wrap: wrap;
          .item {
            cursor: pointer;
            padding: 14px;
            width: 33%;
            .itemImg {
              width: 100%;
              & > img {
                width: 100%;
              }
            }
            .text {
              font-family: Noto Sans CJK TC;
              font-style: normal;
              font-weight: normal;
              font-size: 20px;
              line-height: 36px;
              display: flex;
              align-items: center;
              color: #242c3f;
            }

            .purchaseTime {
              font-family: Noto Sans CJK TC;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 24px;
              display: flex;
              align-items: center;
              color: #8b90a0;
            }
          }
        }
      }
    }
  }
}
